import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import ModalFooter from "reactstrap/es/ModalFooter";
import Button from "reactstrap/es/Button";
import Modal from "reactstrap/es/Modal";
import React, {useEffect, useState} from "react";
import {SmartGroupVertical, SmartSelect, SmartTextarea, SmartDropzone, useSmartForm, SmartInput} from "@csas-smart/smart-ui-comps";
import {createFileKey} from "@csas-smart/smart-ui-comps/dist/controlled/smartFile";
import {useDispatch, useSelector} from "react-redux";
import {
} from "../../core/actions";
import LoadingLayer from "../form/loadinglayer";
import AlertInfo from "../feedback/AlertInfo";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";
import {reportIncident, reportIncidentCleared} from "../../core/actions/reportActions";


const ReportIncidentModal = ({isOpen, onExit}) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [priority, setPriority] = useState('2');
    const [files, setFiles] = useState({
        accepted: [],
        rejected: []
    });

    const reportIncidentFailed = useSelector(state => state?.reportIncident?.error);
    const reportIncidentLoading = useSelector(state => state?.reportIncident?.loading);

    const smartForm = useSmartForm();

    useEffect(() => {
        setTitle('');
        setDescription('');
        setPriority('2');
        dispatch(reportIncidentCleared());
        setFiles({
            accepted: [],
            rejected: []
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        console.log(files)
    }, [files])

    const dispatch = useDispatch();

    const submitForm = () => {
        const data = {
            description: description,
            files: files.accepted,
            priority: priority,
            subject: title,
            system: 'SAC_SMART_SES'
        };
        dispatch(reportIncident(data)).then(() => onExit());
    };

    const getPriorityOptions = () => {
        return [
            {id: 1, label: "1 - vysoká", value: "1"},
            {id: 2, label: "2 - střední", value: "2"},
            {id: 3, label: "3 - nízká", value: "3"}
        ]
    };

    const onTitleChanged = (e) => {
        setTitle(e.target.value);
    };

    const onDescritionChanged = (e) => {
        setDescription(e.target.value);
    };

    const onPriorityChanged = (e) => {
        setPriority(e.target.value);
    };

    const onFilesAcceptedDrop = (acceptedFiles) => {
        let filtered = acceptedFiles.filter(f => !files.accepted.some(acc => createFileKey(f) === createFileKey(acc)));
        setFiles({...files, accepted: [...files.accepted, ...filtered]});
    };

    const onFilesRejectedDrop = (rejectedFiles) => {
        let filtered = rejectedFiles.filter(d => !files.rejected.some(rej => createFileKey(rej) === createFileKey(d.file)));
        setFiles({...files, rejected: [...files.rejected, ...filtered]});
    };

    const onAcceptedFileRemove = (file) => {
        let remainingFiles = [...files.accepted.filter(f => createFileKey(f) !== createFileKey(file))];
        setFiles({...files, accepted: remainingFiles});
    };

    const onRejectedFileRemove = (file) => {
        let remainingFiles = [...files.rejected.filter(d => createFileKey(d.file) !== createFileKey(file))];
        setFiles({...files, rejected: remainingFiles});
    };

    const isFilesValid = () => {
        return !files.rejected || files.rejected.length === 0;
    }

    const getText = () => {
        return (
            <div className="text-center">
                <span className="d-block">
                    Pro nahrání příloh <span className="font-weight-bold">klikněte</span> do rámečku, a nebo do něj přílohy přetáhněte myší.
                </span>
                <span className="d-block">
                    Akceptované typy příloh jsou <span className="font-weight-bold">image/jpeg, image/png, application/pdf a ms-office dokumenty</span>
                </span>
            </div>
        )
    };

    return (
        <Modal isOpen={isOpen} onExit={onExit} toggle={onExit} size="lg">
            {isOpen ?
                <>
                    <ModalHeader toggle={onExit} className="bg-primary text-white font-weight-bold">
                        Nahlásit problém
                    </ModalHeader>
                    <ModalBody>
                        <LoadingLayer isLoading={reportIncidentLoading}>
                            <Row>
                                <Col>
                                    <SmartGroupVertical label="Předmět"
                                                        id="sg_incident_title"
                                                        target={"incident_title"}
                                                        form={smartForm} className="px-0">
                                        <SmartInput id="incident_title"
                                                    rules={{required: true, minLength: 10, minLengthMsg:"Musí obsahovat alespoň 10 znaků", maxLength: 200, maxLengthMsg:"Smí obsahovat maximálně 200 znaků"}}
                                                    form={smartForm}
                                                    name="incident_title"
                                                    placeholder="Zadejte předmět"
                                                    size="lg"
                                                    value={title}
                                                    onChange={(e) => onTitleChanged(e)}/>
                                    </SmartGroupVertical>

                                    <SmartGroupVertical label="Popis incidentu"
                                                        id="sg_incident_description"
                                                        target={"incident_description"}
                                                        form={smartForm} className="px-0 mt-2">
                                        <SmartTextarea id="incident_description"
                                                       rules={{required: true, minLength: 20, minLengthMsg:"Musí obsahovat alespoň 20 znaků"}}
                                                       form={smartForm}
                                                       name="incident_description"
                                                       rows={5}
                                                       placeholder="Popište problém"
                                                       size="lg"
                                                       value={description}
                                                       onChange={(e) => onDescritionChanged(e)}/>
                                    </SmartGroupVertical>

                                    <SmartGroupVertical label="Naléhavost"
                                                        id="sg_incident_priority"
                                                        target={"incident_priority"}
                                                        form={smartForm}  className="px-0 mt-2">
                                        <SmartSelect
                                            id="incident_priority"
                                            rules={{required: true}}
                                            form={smartForm}
                                            size="lg"
                                            name="incident_priority"
                                            value={priority}
                                            options={getPriorityOptions()}
                                            onChange={(e) => onPriorityChanged(e)}
                                            excludeNull={true}/>
                                    </SmartGroupVertical>

                                    <SmartGroupVertical label="Přílohy" id="sg_incident_files" target={"files"} className="px-0 mt-2"
                                                        form={smartForm}>
                                        <SmartDropzone multiple={true}
                                                       accept='image/jpeg, image/png, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                       text={getText()}
                                                       acceptedFiles={files.accepted}
                                                       rejectedFiles={files.rejected}
                                                       onFilesAcceptedDrop={onFilesAcceptedDrop}
                                                       onAcceptedFileRemove={onAcceptedFileRemove}
                                                       onFilesRejectedDrop={onFilesRejectedDrop}
                                                       onRejectedFileRemove={onRejectedFileRemove}
                                                       id="files"
                                                       rules={{
                                                           validate: isFilesValid(),
                                                           validateMsg: 'Nahrané soubory musí být validní.'
                                                       }}
                                                       form={smartForm}
                                        />
                                    </SmartGroupVertical>
                                </Col>
                            </Row>

                            {reportIncidentFailed ?
                                <Row>
                                    <Col>
                                        <AlertInfo color="danger" label="Při zakládání problému do ServiceNow se něco pokazilo. Omlouváme se za způsobené komplikace. "
                                                   hideClose={true}/>
                                    </Col>
                                </Row> : null}
                        </LoadingLayer>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onExit} className="mr-2 float-left" size="lg">Zavřít</Button>
                        <Button onClick={() => smartForm.onSubmit(submitForm)} className="mr-2"
                                disabled={reportIncidentLoading} size="lg"
                                color="primary">Nahlásit</Button>
                    </ModalFooter>
                </>
                : null}
        </Modal>
    );
}

export default ReportIncidentModal;
