import React, {useEffect, useRef, useState} from "react";
import Input from "reactstrap/es/Input";
import Clear from "./startClear";
import InputGroupAddon from "reactstrap/es/InputGroupAddon";
import {Button} from "reactstrap";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import InputGroup from "reactstrap/es/InputGroup";

const StartInput = ({icon, clearable, placeholder, value, onChange, onIconClick, id, className, onKeyDown, delay, onDelay, setRef, onFocus, onBlur, onClear, showReset, onClick, disabled}) => {

    const wrapperRef = useRef();
    const [height, setHeight] = useState(0);
    const [mount, setMounted] = useState(false);

    useEffect(() => {
        setHeight(wrapperRef.current.clientHeight);
    }, [wrapperRef]);

    useEffect(() => {
        if(!mount){
            setMounted(true);
            return;
        }
        if(delay && onDelay) {
            const timeout = setTimeout(() => {
                onDelay();
            }, delay);
            return () => clearTimeout(timeout);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const getWrapperClasses = () => {
        let classes = [];
        classes.push(className ? className : '');
        classes.push(clearable ? 'clearable' : '');
        classes.push('position-relative');
        classes.push('flex-grow-1');
        return classes.join(" ");
    };

    const handleOnClear = (e) => {
        if(onClear){
            onClear(e);
        }else {
            onChange({target: {value: ''}})
        }
    };

    const handleShowReset = () => {
        if(showReset){
            return showReset();
        }else{
            return value && value.length > 0
        }
    };

    const setReference = (ref) => {
        if(setRef) {
            setRef(ref);
        }
    };

    return (
        <>
            <div className={getWrapperClasses()} ref={wrapperRef}>
                <InputGroup size="md">
                    <Input bsSize="lg"
                           innerRef={setReference}
                           value={value}
                           onKeyDown={onKeyDown}
                           placeholder={placeholder}
                           onFocus={onFocus}
                           onBlur={onBlur}
                           id={id}
                           className="input"
                           onClick={onClick}
                           onChange={onChange}
                           disabled={disabled}/>
                    <Clear id={id + '-input-clear-btn'}
                           title="Odstranit text"
                           parentHeight={height}
                           clearable={clearable}
                           onClear={handleOnClear}
                           showReset={handleShowReset()}/>
                    <InputGroupAddon addonType="append">
                        <Button color="hint" outline size="md" onClick={onIconClick} disabled={disabled}>
                            <FontGeorgeIcon className="mt-auto" icon={icon} width={22} height={22}/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        </>
    );


};

export default StartInput;