import {
    CLIENT_SEARCH_TEXT_CHANGED,
    PERSON_MEP_TOKEN,
    CLIENT_LOADING,
    PERSON_INFO_LOADED,
    CLIENT_CLEARED,
    CLIENT_NOT_FOUND,
    CLIENT_LOADING_FAILED,
    REPRESENTATIVES_LOADING,
    REPRESENTATIVES_LOADED,
    SET_REPRESENTATIVE,
    REPRESENTATIVES_LOADING_FAILED,
    REPRESENTATIVES_CLEARED,
    SET_CLIENT_TYPE,
    SET_COMPANY,
    SET_PERSON,
    SET_AUTHORIZED_PERSON
} from '../core/consts';
import {updateClientInfo} from "./utils/clientUtils";

const initialState = {
    form: {searchText: ""},
    clientType: "",
    representatives: [],
    selectedRepresentatives: [],
    authorizedPerson: undefined,
    loading: false,
    representativesLoading: false,
    data: undefined,
    failed: false
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
    case CLIENT_SEARCH_TEXT_CHANGED:
        return {...state, form: {searchText: action.text} };
    case CLIENT_LOADING:
        return {...state, loading: true, failed: false};
    case SET_COMPANY:
        let companyData = action.data;
        companyData.timestamp = new Date();
        return {...state, data: companyData, clientType: 'COMPANY', loading: false, failed: false};
    case SET_PERSON:
        let personData = action.data;
        personData.timestamp = new Date();
        return {...state, data: personData, clientType: 'PERSON', loading: false, failed: false};
    case CLIENT_LOADING_FAILED:
        return {...state, loading: false, failed: true};
    case CLIENT_NOT_FOUND:
            return {...state, loading: false};
    case PERSON_MEP_TOKEN:
        return {...state, mepToken : action.mepToken};
    case PERSON_INFO_LOADED:
        if (!state.data) {
            return state;
        }
        return updateClientInfo(state, action);
        case CLIENT_CLEARED:
        return {
            ...state,
            clientType: "",
            authorizedPerson: undefined,
            representatives: [],
            selectedRepresentatives: [],
            data: undefined
        };
    case SET_CLIENT_TYPE:
        return {...state, clientType: action.clientType};
    case REPRESENTATIVES_LOADING:
        return {...state, representativesLoading: true, failed: false};
    case REPRESENTATIVES_LOADED:
        let representatives = [];
        action.data.company.isSelfEmployed ? representatives.push(action.data.company.person) : representatives = action.data.company.representatives;
        return {...state, representativesLoading: false, representatives: representatives, failed: false};
    case REPRESENTATIVES_LOADING_FAILED:
        return {...state, representativesLoading: false, failed: true};
    case REPRESENTATIVES_CLEARED:
        return {...state, data: undefined};
    case SET_REPRESENTATIVE:
        let representative = action.customerId;
        let selectedRepresentatives = state.selectedRepresentatives;
        selectedRepresentatives = [representative];

        //TODO CD-54127 - přidání více jednatelů
        /*if (selectedRepresentatives.includes(representative)) {
            selectedRepresentatives = selectedRepresentatives.filter(item => item !== representative);
        } else {
            selectedRepresentatives.push(representative);
        }*/
        return {...state, selectedRepresentatives: selectedRepresentatives};
    case SET_AUTHORIZED_PERSON:
        return {...state, authorizedPerson: action.data};
    default:
        return state;
    }
};

export default clientReducer;
