import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCodebook, getCodebookValue} from "../../core/codebook";
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";

const ClientAlerts = ({id, clientAlerts, className, activeOnly, cluid}) => {

    const dispatch = useDispatch();
    const cbAlertCategory = useSelector(state => state.codebook.codebooks['CB_AlertCategory']);
    const cbClientAlertType = useSelector(state => state.codebook.codebooks['CB_ClientAlertType']);
    const crmBaseUrl = useSelector(state => state.environment.properties['crm.app.url']);
    const crmPathAppUrl = useSelector(state => state.environment.properties['crm.path.app.url']);
    const crmSuffixAppUrl = useSelector(state => state.environment.properties['crm.suffix.app.url']);

    const completeCrmAppUrl = crmBaseUrl + crmPathAppUrl + "?SWECmd=InvokeMethod&SWEMethod=Search&SWEService=CSAS+GUI+Integration&ViewName=CSAS+JPP+Contact+Client+Dashboard+View&BusObject=Contact&BusComp=Contact&CSAS+Unique+Client+Id="+ cluid + crmSuffixAppUrl;


    useEffect(() => {
        dispatch(getCodebook('CB_AlertCategory'));
        dispatch(getCodebook('CB_ClientAlertType'));
    });

    const hasAlerts = () => {
        return clientAlerts?.filter(ca => !activeOnly || ca.active)?.length > 0
    };

    const getTranslatedType = (type) => {
        if(cbClientAlertType) {
            return getCodebookValue(cbClientAlertType, type);
        }
    };

    const getTranslatedCategory = (category) => {
        if(cbAlertCategory) {
            return getCodebookValue(cbAlertCategory, category);
        }
    };

    const formatAlerts = () => {
        return (
            <>
                <Row>
                    <Col className="border-bottom border-light">
                        {clientAlerts.filter(ca => !activeOnly || ca.active).map(ca =>
                            <p className="mb-2">
                                <span className="font-weight-bold">{getTranslatedCategory(ca.category)}: </span><span>{getTranslatedType(ca.type)}</span>
                            </p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col className="py-2">
                        <a className="text-light" target="_blank" rel="noopener noreferrer" href={completeCrmAppUrl}>Zobrazit v CRM</a>
                    </Col>
                </Row>
            </>
        )
    };

    return (
        <>
            {hasAlerts() ?
                <>
                    <div id={id} className={"bg-warning d-inline-block p-1 selectable circle white-icon " + className}>
                        <FontGeorgeIcon icon="warning" width={20} height={20} popover={formatAlerts()} id={"ico-"+id} className="center"/>
                    </div>
                </> : null}
        </>
    )
};

export default ClientAlerts;