import React, {useEffect, useRef} from 'react';
import {Card} from 'reactstrap';
import CardFooter from "reactstrap/es/CardFooter";
import CardBody from "reactstrap/es/CardBody";
import Button from "reactstrap/es/Button";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import {SmartOverlay} from "@csas-smart/smart-ui-comps";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

const CaseCard = ({icon, label, description, onClick, className, caseType, george, onEffect, btnLabel, btnColor, frequency, side, selected, loading}) => {

    let headerRef = useRef();
    let textRef = useRef();

    useEffect(() => {
        if (onEffect) {
            onEffect(headerRef, textRef, side)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const getLoadingUI = () => {
        return (
            <>
                <FontAwesomeIcon icon={faSpinner} spin={true} size="4x" className="text-primary"/>
            </>
        )
    };

    return (
        <Card className={className}>
            <SmartOverlay processing={loading} loadingUI={getLoadingUI()}>
                {frequency && caseType.frequency && caseType.frequency > 0 ?
                    <div className="frequency-icon">
                        <FontGeorgeIcon icon="star" width={40} height={40} popover={"Nejčastěji zadávané"}
                                        id={"frequency-icon-" + caseType.caseType}/>
                    </div> : null}

                {caseType.gtiLink && george ?
                    <div className="george-icon">
                        <FontGeorgeIcon icon="george" width={40} height={40}/>
                    </div> : null}
                <CardBody className="text-center">
                    <div className="my-3">
                        <FontGeorgeIcon icon={icon} width={80} height={80}/>
                    </div>
                    <div>
                        <h4 className="font-weight-bold" ref={headerRef} title={caseType.caseType}>{label}</h4>
                    </div>
                    <span className="d-block font-size-115 pt-2" ref={textRef}>{description}</span>
                </CardBody>
                <CardFooter className="position-relative py-4">
                    {onClick ?
                        <Button outline size="lg" color={btnColor ? btnColor : 'primary'}
                                onClick={(e) => onClick(e)} className="position-relative">
                            {btnLabel ? btnLabel : 'Vybrat'}
                        </Button>
                        : null}
                </CardFooter>
            </SmartOverlay>
        </Card>
    )

};

export default CaseCard;