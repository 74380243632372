import React, {useEffect, useState} from "react";

const CountdownInfo = ({ expireTime, className, onExpire}) => {

    const getTime = () => {
        return Math.floor((expireTime.getTime() - new Date().getTime()) / 1000);
    };

    const [timeLeft, setTimeLeft] = useState(getTime());

    useEffect(() => {
        //1] If time left is lesser than 0 or zero stop
        if (!timeLeft || timeLeft < 0) {
            if(onExpire){
                onExpire();
            }
            return;
        }

        const intervalId = setInterval(() => {
            let timeLeft = getTime();
            setTimeLeft(timeLeft);
        }, 1000);

        return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    const secondsToTime = () => {
        let hours = Math.floor(timeLeft / (60 * 60));

        let divisor_for_minutes = timeLeft % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return toDoubleDigits(hours) + ":" + toDoubleDigits(minutes) + ":" + toDoubleDigits(seconds);
    };

    const toDoubleDigits = (number) => {
        return number.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })
    };

    return (
        <div className={className}>
            {secondsToTime()}
        </div>
    );
};

export default CountdownInfo;