import { env } from "../configuration/config";

class Resource {

    getEnv() {
        return env.config.environment;
    }

    getSesApi() {
        return env.config.webApiUrl + "/starter";
    }

    getWebApiUrl() {
        return env.config.webApiUrl;
    }

    getWebApiKey() {
        return env.config.webApiKey;
    }

    getAdfsUrl() {
        return env.config.adfsUrl;
    }

    checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            return Promise.reject(response);
        }
    }

    getJsonBody(response) {
        if (response.status === 204) {
            return Promise.resolve();
        }

        return response.json();
    }

}

export default new Resource();
