import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {getGtiLink, selectCaseType, caseTypeGtiLinkLoadingFailed} from '../../core/actions';
import CaseCard from "./caseCard";
import {APPLICATION_CODE, STARTER_CASE_SELECTED} from "../../core/consts";
import {fireEvent} from "../../core/actions/eventActions";

const CaseTypeCard = ({caseType, selected, onStart, gtiLink}) => {
    const dispatch = useDispatch();
    let [height, setHeight] = useState(292);
    const fixedCardSize = 190;

    useEffect(() => {
        if (isSelected()) {
            if((caseType.ready || caseType.module) && caseType.startBti && !gtiLink && !selected.loading && !selected.failed){
                dispatch(getGtiLink("STARTER", caseType.caseType, caseType.tags))
                    .catch(() => {dispatch(caseTypeGtiLinkLoadingFailed())});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const handleEffect = (headerRef, textRef, side) => {
        if (headerRef.current && textRef.current) {
            let newHeight = headerRef.current.clientHeight + textRef.current.clientHeight + fixedCardSize;
            if (newHeight > height || side === "front")
                setHeight(headerRef.current.clientHeight + textRef.current.clientHeight + fixedCardSize)
        }
    };

    const getClasses = () => {
        let classes = [];
        classes.push("card-case-type");
        classes.push("m-3");
        classes.push('flip-card');
        classes.push('selectable');
        classes.push(selected?.caseType === caseType.caseType ? 'flipped' : '');
        return classes.join(" ");
    };

    const isInvalid = () => {
        const invalid = selected?.caseType === caseType.caseType && !caseType.ready;
        if(caseType.startBti && (!invalid || caseType.module)){
            return !gtiLink;
        }
        return invalid;
    };

    const isSelected = () => {
        return selected?.caseType === caseType.caseType;
    };

    const handleCardClicked = (e) => {
        if (e) {
            e.stopPropagation()
        }

        dispatch(selectCaseType(caseType));
        dispatch(fireEvent(STARTER_CASE_SELECTED));
    };

    const resolveIcon = () => {
        return caseType?.icon ? caseType?.icon : APPLICATION_CODE[caseType.application]?.icon || 'bank-xl';
    };

    const classesFront = () => {
        let classes = [];
        classes.push('flip-card-front');
        classes.push(caseType.ready ? 'bg-front' : 'bg-secondary');
        classes.push(caseType.gtiLink ? 'george' : '');
        classes.push(isSelected() ? 'selected' : '');
        classes.push(caseType.frequency > 0 ? 'frequency' : '');
        classes.push(isSelected() ? 'btn-hide' : '');
        return classes.join(' ');
    };

    return (
        <div id={caseType.caseType} className={getClasses()} onClick={handleCardClicked} style={{minHeight: height + 'px'}}>
            <div className="flip-card-inner">
                <CaseCard className={classesFront()}
                          side={"front"}
                          selected={isSelected()}
                          caseType={caseType}
                          onEffect={handleEffect}
                          icon={resolveIcon()}
                          label={caseType.label}
                          george={true}
                          frequency={true}
                          onClick={handleCardClicked}
                          description={caseType?.categorizationLabel || caseType.description}/>
                {isInvalid() && isSelected() ?
                    <CaseCard className={"flip-card-back " + (selected.loading ? 'bg-white' : 'bg-danger')}
                              side={"back"}
                              loading={selected.loading}
                              selected={selected}
                              caseType={caseType}
                              onEffect={handleEffect}
                              icon="info"
                              label={caseType.label}
                              description={caseType?.explanation || caseType.categorizationExpression}/> : null}
                {!isInvalid() && isSelected() ?
                    <CaseCard className="flip-card-back text-white bg-success"
                              caseType={caseType}
                              selected={selected}
                              icon="checkmark-xl"
                              label={caseType.label}
                              onClick={onStart}
                              btnLabel="Zahájit"
                              btnColor="success"
                              description="Požadavek je připraven ke spuštění"/> : null}
            </div>
        </div>
    )

};

export default CaseTypeCard;